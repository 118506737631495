import { _js } from '@ifixit/localize';
import { useCart, useCheckout } from '@ifixit/shopify-cart-sdk';
import trackInPiwikAndGA from 'Shared/Analytics/CombinedGAPiwik';

declare global {
   interface Window {
      shared_constants: Record<string, any>;
   }
}

const Footer = ({ cartUrl, storeUrl }: { cartUrl: string; storeUrl: string }) => {
   const cart = useCart();
   const { checkoutUrl: shopifyCheckoutUrl } = useCheckout();
   const products = cart.data?.lineItems;
   const checkoutButtonAttributes = {
      href: shopifyCheckoutUrl,
      onClick: () => {
         trackInPiwikAndGA({
            eventCategory: 'Mini Cart',
            eventAction: 'Mini Cart - Btn "Check Out" - Click',
         });
      },
   };

   const viewCartButtonAttributes = {
      onClick: (): void => {
         trackInPiwikAndGA({
            eventCategory: 'Mini Cart',
            eventAction: 'Mini Cart - Btn "View Cart" - Click',
         });
      },
   };

   return products?.length ? (
      <div className="mini-cart-footer">
         <a
            className="mini-cart-button button button-transparent"
            href={cartUrl}
            {...viewCartButtonAttributes}
         >
            {_js('View Cart')}
         </a>
         <a
            className="mini-cart-button button button-action-solid js-check-out-fb-track-button"
            {...checkoutButtonAttributes}
         >
            {_js('Checkout')}
         </a>
      </div>
   ) : (
      <div className="mini-cart-footer">
         <a className="mini-cart-button button button-action-solid" href={storeUrl}>
            {_js('Browse Our Store')}
         </a>
      </div>
   );
};

export default Footer;
