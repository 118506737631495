import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { color, space, fontSize, fontWeight } from '@ifixit/primitives';
import MenuItem from '../links/menu_item';
import StoreSelector from './store/store_selector';
import { HeadingLinkType, StoreType, SublinkType } from '.';

export const Title = styled.div`
   color: ${color.white};
   display: flex;
   align-items: center;
   font-size: ${fontSize.md};
   font-weight: ${fontWeight.semiBold};
   margin: ${space[0]};
   padding: 0 ${space[5]};
   white-space: nowrap;
`;

export const Menu = styled.div`
   position: absolute;
   display: none;
   left: 0;
   top: 100%;
   background: ${color.gray[800]};
`;

const HeadingContainer = styled.li<{ $dropdownOpen: boolean }>`
   display: flex;
   align-items: stretch;
   max-width: 236px;

   &:hover {
      background: ${color.gray[800]};
      cursor: default;
   }

   &.js-menu-is-open {
      background: ${color.gray[800]};
      cursor: pointer;
      z-index: 1;
   }

   &:focus {
      outline: none;
   }

   ${({ $dropdownOpen }) =>
      $dropdownOpen
         ? ''
         : `
            &:hover,
            &.js-menu-is-open {
               ${Menu} {
                  display: flex;
               }
            }`}

   &:hover {
      ${Menu} {
         z-index: 1;
      }
   }
`;

const Heading = ({
   info,
   isActive,
   headingOpened,
   stores,
}: {
   info: HeadingLinkType;
   isActive: boolean;
   headingOpened: (openedId: any) => void;
   stores: StoreType[];
}) => {
   const [dropdownOpen, setDropdownOpen] = useState(false);

   const keyPressed = useCallback(
      (e: any) => {
         if (e.key === 'Enter') {
            headingOpened(info.title);
         }
      },
      [headingOpened]
   );

   return (
      <HeadingContainer
         tabIndex={0}
         onKeyDown={keyPressed}
         // @ts-expect-error TS(2769) FIXME: No overload matches this call.
         className={isActive && 'js-menu-is-open'}
         $dropdownOpen={dropdownOpen}
      >
         <Title>{info.title}</Title>
         {info.hasStoreDropdown && (
            <StoreSelector
               stores={stores}
               dropdownOpen={dropdownOpen}
               setDropdownOpen={setDropdownOpen}
            />
         )}
         {info.sublinks && (
            <Menu>
               {info.sublinks.map(
                  (sublink: SublinkType) =>
                     sublink.disabled || <MenuItem key={`${sublink.title}-sublink`} {...sublink} />
               )}
            </Menu>
         )}
      </HeadingContainer>
   );
};

export default Heading;
