import styled from 'styled-components';
import Dropdown from './dropdown';
import LanguageDropdown from './language_dropdown';
import LanguageButton from './language_button';
import trackInPiwikAndGA from 'Shared/Analytics/CombinedGAPiwik';

const LanguageDropdownWrapper = styled(LanguageDropdown)`
   a:hover {
      text-decoration: none;
   }
`;

const LanguageDropdownContainer = ({
   languages,
   translationPreferencesUrl,
}: {
   languages: any[];
   translationPreferencesUrl: string;
}) => {
   const selectedLanguage = languages.find((language: any) => language.isSelected);
   const onMount = () => {
      trackInPiwikAndGA({
         eventCategory: 'Language',
         eventAction: 'Language Dropdown - Click',
      });
   };

   return (
      <Dropdown
         label="Choose language"
         icon={<LanguageButton isoCode={selectedLanguage.isoCode} />}
         onMount={onMount}
      >
         <LanguageDropdownWrapper
            translationPreferencesUrl={translationPreferencesUrl}
            languages={languages}
            isLightTheme={true}
         />
      </Dropdown>
   );
};

export default LanguageDropdownContainer;
