import { borderRadius, color, fontFamily, fontSize, space } from '@ifixit/primitives';
import { string } from 'prop-types';
import styled from 'styled-components';

const languageBoxSize = '22px';

const Container = styled.div`
   border-radius: ${borderRadius.sm};
   margin-block: ${space[1]};
   padding: 0;
   width: ${languageBoxSize};
   height: ${languageBoxSize};
   line-height: ${languageBoxSize};
   color: ${color.gray[800]};
   background-color: ${color.white};
   transition: background-color 0.1s;
   font-family: ${fontFamily.monoSystem};
   font-weight: bold;
   font-size: ${fontSize.sm};
   display: flex;
   align-items: center;
   justify-content: center;

   &:hover {
      background-color: ${color.gray[200]};
   }
`;

const LanguageButton = ({ isoCode }: { isoCode: string }) => (
   <Container id="js-iso-language-dropdown-button">{isoCode}</Container>
);

LanguageButton.propTypes = {
   isoCode: string.isRequired,
};

export default LanguageButton;
