import { _js } from '@ifixit/localize';
import { useState } from 'react';
import styled from 'styled-components';
import WorkbenchManager from 'Shared/workbench_manager';
import { TopicSelector } from 'Shared/topic_selector';
import { color, space } from '@ifixit/primitives';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';

const RemoveDeviceContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   padding: ${space[0]} ${space[0]} ${space[0]} ${space[2]};
   height: 24px;
   color: ${color.gray[600]};

   &:hover,
   &:focus {
      color: ${color.red[500]};
   }
`;

const WorkbenchTopic = styled.div`
   display: flex;
   flex-direction: column;
   padding: ${space[4]};
   cursor: pointer;
   transition: background-color 0.1s ease-in-out;
   border-top: 1px solid ${color.gray[300]};

   background-color: ${props => (props.$open ? color.gray[50] : color.white)};

   a:hover {
      color: ${color.title};
   }

   &:hover,
   &:focus {
      background-color: ${color.gray[50]};
   }
`;

const WorkbenchTopicInfoContainer = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
`;

const WorkbenchImage = styled.img`
   height: 60px;
   width: auto;
   mix-blend-mode: multiply;
`;

const TopicVariantsContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   width: 100%;
   height: 100%;
   overflow: hidden;
   margin-left: ${space[3]};
   padding: ${space[1]};
`;

const TopicTitle = styled.p`
   color: ${color.gray[600]};
   font-size: 14px;
   font-weight: 700;
   padding: ${space[0]};
   text-decoration: none;

   &:hover {
      color: ${color.gray[600]};
      background: inherit;
   }
`;

const TopicVariant = styled.p`
   margin: ${space[0]};
`;

const TopicActionContainer = styled.div`
   padding-top: ${props => (props.$open ? '16px' : '0px')};

   visibility: ${props => (props.$open ? 'visible' : 'hidden')};
   opacity: ${props => (props.$open ? 1 : 0)};

   max-height: ${props => (props.$open ? '200px' : '0px')};

   display: flex;
   justify-content: stretch;

   overflow: hidden;

   // we do not want an animation on card close
   transition: ${props =>
      props.$open ? 'visibility 0s, max-height 0.3s ease, opacity 0.15s ease-in' : ''};
`;

const TopicActionAnchor = styled.a`
   width: 100%;
   margin-left: 1em;
   padding: ${space[3]} ${space[2]};

   color: ${color.gray[600]};
   background-color: ${color.gray[50]};

   text-align: center;
   font-size: 12px;
   font-weight: bold;
   cursor: pointer;

   border-color: ${color.gray[400]};
   border-radius: 5px;
   border-width: thin;
   border-style: solid;

   &:first-child {
      margin-left: ${space[0]};
   }

   &:hover,
   &:focus {
      background-color: ${color.white};
      text-decoration: none;
   }
`;

const WorkbenchItem = ({ topic }) => {
   const [actionContainerOpen, setActionContainerOpen] = useState(false);

   const callFuncOnEnterDown = (event, func) => {
      event.stopPropagation();

      if (event.key === 'Enter') {
         func();
      }
   };

   const toggleOpen = () => {
      setActionContainerOpen(!actionContainerOpen);
   };

   const removeDevice = (event = null) => {
      if (event) {
         event.stopPropagation();
      }

      let topicVariantid = topic.variantid || null;

      WorkbenchManager.removeItem(topicVariantid, topic.wikiid, /* useIndicator */ false);

      topicVariantid = topicVariantid || 'generic';

      TopicSelector.uncheckVariant(topicVariantid);
   };

   return (
      <WorkbenchTopic
         $open={actionContainerOpen}
         data-wikiid={topic.wikiid}
         data-topic-variantid={topic.variantid || null}
         onClick={toggleOpen}
         onKeyDown={e => callFuncOnEnterDown(e, toggleOpen)}
         role="button"
         tabIndex="0"
      >
         <WorkbenchTopicInfoContainer>
            <WorkbenchImage src={topic.image} />
            <TopicVariantsContainer>
               <TopicTitle>{topic.display_title}</TopicTitle>
               {topic.variantid && <TopicVariant>{topic.variant}</TopicVariant>}
            </TopicVariantsContainer>
            <RemoveDeviceContainer
               onClick={removeDevice}
               onKeyDown={e => callFuncOnEnterDown(e, removeDevice)}
               role="button"
               tabIndex="0"
            >
               <FontAwesomeIcon icon={faXmark} size="xl" />
            </RemoveDeviceContainer>
         </WorkbenchTopicInfoContainer>
         <TopicActionContainer $open={actionContainerOpen}>
            {/* Translators: Link to the guides page for a device */}
            <TopicActionAnchor href={topic.topic_url}>{_js('Guides')}</TopicActionAnchor>
            {/* Translators: Link to the answers page for a device */}
            <TopicActionAnchor href={topic.answers_url}>{_js('Answers')}</TopicActionAnchor>
            {topic.parts_url && (
               <TopicActionAnchor href={topic.parts_url}>{_js('Parts')}</TopicActionAnchor>
            )}
         </TopicActionContainer>
      </WorkbenchTopic>
   );
};

export default WorkbenchItem;
