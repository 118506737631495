import { _js } from '@ifixit/localize';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { color, fontSize } from '@ifixit/primitives';
import { SearchBar } from '..';
import * as HeaderActions from 'Shared/Header/actions';

const Container = styled.div<{ $isOpen?: boolean }>`
   position: absolute;
   display: flex;
   align-items: center;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   padding: 0 17px 0 28px;
   transition: all 0.25s;
   opacity: 1;
   pointer-events: auto;
   ${props =>
      !props.$isOpen &&
      css`
         transform: translateY(-100%);
         opacity: 0;
         pointer-events: none;
      `};
`;

const Text = styled.a`
   padding: 8px 16px;
   font-weight: normal;
   font-size: ${fontSize.md};
   color: ${color.white};

   &:hover {
      text-decoration: none;
   }
`;

const mapStateToProps = (state: any) => ({
   headerState: state.headerState,
});

const mapDispatchToProps = (dispatch: any) => ({
   toggleMobileSearch: (value: any) => dispatch(HeaderActions.toggleMobileSearch(value)),
});

const MobileSearch = ({
   isOnCart,
   headerState,
   toggleMobileSearch,
}: {
   isOnCart: any;
   headerState: { displayMobileSearch: any };
   toggleMobileSearch: (val: boolean) => void;
}) => {
   const { displayMobileSearch } = headerState;

   return (
      <Container $isOpen={displayMobileSearch}>
         <SearchBar isOnCart={isOnCart} isMobile tabbable={displayMobileSearch} />
         <Text
            href="#"
            onClick={() => toggleMobileSearch(false)}
            tabIndex={displayMobileSearch ? 0 : -1}
         >
            {_js('Cancel')}
         </Text>
      </Container>
   );
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileSearch);
