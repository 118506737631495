import { _js } from '@ifixit/localize';
import styled from 'styled-components';
import { color } from '@ifixit/primitives';
import MenuButton from './menu_button';
import NotificationCard from './notification_card';

const List = styled.ul`
   display: flex;
   flex-flow: column nowrap;

   margin: 0;
   padding: 0;
   list-style-type: none;
   background-color: ${color.white};

   li {
      min-width: 300px;
   }
`;

const ButtonMenu = styled.li`
   display: flex;
   flex-flow: row wrap;
   align-items: center;
`;

const Button = styled(MenuButton)`
   padding: 10px;
   flex: 1;

   border-top: 1px solid ${color.gray[200]};
   border-right: 1px solid ${color.gray[200]};

   &:last-child {
      border-right: 0;
   }
`;

const componentsFromNotifications = (msg: any) => (
   <NotificationCard key={msg.signature} metadata={msg.metadata} markRead={msg.markRead} />
);

function NotificationsDisplay({
   unreadNotifications,
   notificationsUrl,
   markAllRead,
}: {
   unreadNotifications: any[];
   notificationsUrl: string;
   markAllRead: () => void;
}) {
   return (
      <List>
         {unreadNotifications.map(componentsFromNotifications)}
         <ButtonMenu>
            <Button url={notificationsUrl} text={_js('View All')} />
            <Button url="#" text={_js('Clear')} onClick={markAllRead} />
         </ButtonMenu>
      </List>
   );
}

export default NotificationsDisplay;
