import { useRef, useEffect } from 'react';
import { FooterNewsletter } from 'Shared/newsletter';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';
import { FaIcon } from '@ifixit/icons';
import { Text, Stack, Box, Button, Input, FormControl, Link, Flex } from '@chakra-ui/react';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { useHideSubBox } from 'Shared/newsletter_shared_state';
import type { NewsletterFormProps } from '@ifixit/footer/components/Newsletter';

const NewsletterInput = ({ placeholder, label }: { placeholder: string; label: string }) => (
   <FormControl w="full">
      <Input
         type="email"
         variant="filled"
         bg="white"
         color="gray.900"
         fontSize="14px"
         h={{ base: '40px' }}
         _focus={{
            bg: 'white',
            boxShadow: 'outline',
         }}
         placeholder={placeholder}
         aria-label={label}
      />
   </FormControl>
);

const NewsletterHeader = ({ title, subtitle }: { title: string; subtitle: string }) => (
   <Box textAlign="left">
      <Text fontWeight="bold" fontSize="lg" color="white" mb={2} mt={0}>
         {title}
      </Text>
      <Text color="gray.300" fontSize="md" fontWeight="normal" mt={3}>
         {subtitle}
      </Text>
   </Box>
);

const NewsletterForm = ({
   placeholder,
   buttonText,
   label,
}: {
   placeholder: string;
   buttonText: string;
   label: string;
}) => {
   const [hideSubBox] = useHideSubBox();
   if (hideSubBox) {
      return null;
   }
   return (
      <Stack
         as="form"
         direction={{ base: 'row', md: 'column', lg: 'row' }}
         data-testid="footer-newsletter-form"
         spacing="3"
         mt="3"
         w={{
            base: 'full',
            xl: 'auto',
         }}
         justify={{
            base: 'center',
            md: 'flex-end',
         }}
         align="flex-start"
      >
         <NewsletterInput placeholder={placeholder} label={label} />
         <Button
            type="submit"
            bg="blue.ifixit"
            data-testid="footer-newsletter-subscribe-button"
            colorScheme="brand"
            color="white"
            border="none"
            fontWeight="semibold"
            fontSize="md"
            flexShrink={0}
            w={{ base: 'unset', md: 'full', lg: 'unset' }}
            h={{ base: '40px' }}
            _hover={{ bg: 'blue.ifixit', boxShadow: '0 0 5px gray' }}
         >
            {buttonText}
         </Button>
      </Stack>
   );
};

const NewsletterSubscribed = () => {
   const [hideSubBox] = useHideSubBox();
   if (!hideSubBox) {
      return null;
   }

   return (
      <Flex
         height="42px"
         flexDirection="row"
         alignItems="center"
         padding="12px"
         gap="8px"
         w="100%"
         backgroundColor="#EDF6FF"
         border="1px"
         borderColor="#77B5FF"
         borderStyle="solid"
         borderRadius="4px"
         mt="0px !important"
      >
         <FaIcon icon={faCircleCheck} color="#1975F1" />
         <Text color="gray.900" m={0} fontSize="16px">
            {_js('Subscribed!')}
         </Text>
      </Flex>
   );
};

const NewsletterLink = () => {
   const [hideSubBox] = useHideSubBox();
   const message = hideSubBox ? _js('Let me read it!') : _js('Let me read it first!');
   return (
      <Link
         href="/Newsletter"
         color="gray.300"
         marginTop={2}
         textDecoration="underline"
         _hover={{ color: 'white' }}
         _visited={{ color: 'gray.300' }}
      >
         <FaIcon icon={faEye} display="inline" mr={1} verticalAlign="-3px" />
         {message}
      </Link>
   );
};

export const NewsletterComponent = ({
   newsletterForm,
}: {
   newsletterForm: NewsletterFormProps;
}) => {
   const ref = useRef();
   useEffect(() => {
      new FooterNewsletter(ref.current);
   }, []);

   return (
      <Flex
         // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
         ref={ref}
         direction="column"
         mt={{
            base: 4,
            md: 0,
         }}
         w={{ base: 'full', md: '64', lg: '96' }}
      >
         <NewsletterHeader title={newsletterForm.title} subtitle={newsletterForm.subtitle} />
         <NewsletterForm
            placeholder={newsletterForm.inputPlaceholder}
            buttonText={newsletterForm.callToActionButtonTitle}
            label={newsletterForm.title}
         />
         <NewsletterSubscribed />
         <NewsletterLink />
      </Flex>
   );
};
