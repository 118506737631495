import { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { color, fontSize, space } from '@ifixit/primitives';
import merge from 'lodash/merge';
import { useMediaQuery } from 'react-responsive';

const baseTheme = {
   fontSize: fontSize.md,
   dropdownTopBottomPadding: space[5],
   dropdownSidePadding: space[6],
   cardPadding: space[2],
};

const lightTheme = {
   ...baseTheme,
   background: color.white,
   mainText: color.gray[600],
   isoCode: {
      standard: {
         border: color.gray[300],
      },
      hover: {
         font: color.white,
         background: color.blue[400],
         border: color.blue[400],
      },
      selected: {
         font: color.gray[50],
         background: color.blue[500],
         border: color.blue[500],
      },
   },
   languageNameHover: color.gray[500],
};

const darkTheme = {
   ...baseTheme,
   background: '#222222',
   mainText: color.gray[300],
   isoCode: {
      standard: {
         border: color.gray[600],
      },
      hover: {
         font: color.gray[800],
         background: color.gray[50],
         border: color.gray[50],
      },
      selected: {
         font: color.gray[800],
         background: color.gray[400],
         border: color.gray[400],
      },
   },
   languageNameHover: color.white,
};

const makeMobile = (theme: any, smallFont: boolean) => ({
   ...merge(theme, {
      fontSize: smallFont ? fontSize.md : fontSize.lg,
      dropdownTopBottomPadding: space[4],
      dropdownSidePadding: space[4],
      mainText: color.gray[800],
      isoCode: {
         standard: {
            border: color.gray[600],
         },
      },
      cardPadding: space[3],
   }),
});

const LanguageThemeProvider = ({
   isLightTheme,
   mobile,
   children,
}: {
   isLightTheme: boolean;
   mobile: boolean;
   children: ReactNode;
}) => {
   const smallFont = useMediaQuery({ maxWidth: '350px' });

   let theme = isLightTheme ? lightTheme : darkTheme;

   if (mobile) {
      theme = makeMobile(theme, smallFont);
   }

   return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default LanguageThemeProvider;
