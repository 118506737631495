import { Fragment } from 'react';
import trackInPiwikAndGA from 'Shared/Analytics/CombinedGAPiwik';

const withLanguageForm = (LanguagesContainer: any) => (props: any) => {
   const submit = (langid: string) => {
      fetch(props.translationPreferencesUrl, {
         method: 'POST',
         body: JSON.stringify({ langid }),
      }).then(response => {
         response.json().then(json => {
            if (json.redirectUrl) {
               window.location.href = json.redirectUrl;
            } else {
               window.location.reload();
            }
         });
      });
   };

   const onClickLanguage = (clickedLangId: any) => {
      if (!App.canIBypassGAEventFiringOnMobile && App.lang != clickedLangId) {
         trackInPiwikAndGA({
            eventCategory: 'Language',
            eventAction: `Language Switcher - ${App.lang} - ${clickedLangId}`,
         });
      }
      submit(clickedLangId);
   };

   return (
      <Fragment>
         <LanguagesContainer {...props} onClickLanguage={onClickLanguage} />
      </Fragment>
   );
};

export default withLanguageForm;
