import { connect } from 'react-redux';

const mapStateToProps = (state: any) => ({
   notifications: state.headerState.notifications,
});

function withNotifications(DisplayComponent: any) {
   const ComponentWithNotificationService = ({ notifications, ...passThrough }: any) => (
      <DisplayComponent notifications={notifications} {...passThrough} />
   );

   return connect(mapStateToProps)(ComponentWithNotificationService);
}

export default withNotifications;
