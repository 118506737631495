/*
 * This is a dummy Header object to handle calls to the Header object when
 * Hydra is in use (e.g auth.js login uses Header to update Header state).
 *
 * Hydra takes on the responsibilities of the old Header object, but some callsites
 * still use the old functionality, so I decided to override used functions with no-ops.
 * This may seem strange, but it is a better solution than checking for Hydra
 * at every Header object callsite.
 */
// eslint-disable-next-line no-var
export var Header = (window.Header = new Object());

Header.showLoggedIn = _ => {};
Header.showLoggedOut = _ => {};
