import { CardLink } from '../../dropdowns/card_dropdown_parts';
import StoreFlag from './store_flag';
import styled from 'styled-components';
import { color, space, fontSize } from '@ifixit/primitives';
import { StoreType } from '..';

const StoreName = styled.div`
   padding-left: ${space[2]};
   color: ${color.gray[800]};
   font-size: ${fontSize.md};
   line-height: ${fontSize.lg};
`;

const Currency = styled.div`
   color: ${color.gray[400]};
   font-size: ${fontSize.sm};
   line-height: ${fontSize.md};
`;

const StoreNameContainer = styled.div`
   display: flex;
   align-items: center;
`;

interface CardLinkProps {
   $isDisplayStore: boolean;
}

const StyledCardLink = styled(CardLink)<CardLinkProps>`
   display: flex;
   justify-content: space-between;

   padding: ${space[2]};
   padding-left: ${space[3]};
   padding-right: ${space[4]};

   font-style: normal;
   font-weight: normal;

   &:hover {
      background-color: ${color.gray[50]};
   }
`;

const MobileStyledCardLink = styled(StyledCardLink)<CardLinkProps>`
   padding: ${space[3]} ${space[5]};
   font-weight: ${({ $isDisplayStore }) => ($isDisplayStore ? 'bold' : 'normal')};

   ${StoreName} {
      padding-left: ${space[2]};
      font-size: ${fontSize.lg};
      line-height: ${fontSize['2xl']};
   }

   ${Currency} {
      font-size: ${fontSize.md};
      ${({ $isDisplayStore }) => ($isDisplayStore ? `color: ${color.gray[800]};` : '')}
   }
`;

const StoreCard = ({
   store,
   updateStorePreference,
   mobile,
   isDisplayStore,
}: {
   store: StoreType;
   updateStorePreference: () => void;
   mobile?: boolean;
   isDisplayStore: boolean;
}) => {
   const CardLinkType = mobile ? MobileStyledCardLink : StyledCardLink;

   return (
      <CardLinkType
         href="#"
         id={`${store.storeCode}StoreLink`}
         onClick={updateStorePreference}
         $isDisplayStore={isDisplayStore}
      >
         <StoreNameContainer>
            <StoreFlag storeCode={store.storeCode} />
            <StoreName>{store.name}</StoreName>
         </StoreNameContainer>
         <Currency>{store.currency}</Currency>
      </CardLinkType>
   );
};

export default StoreCard;
