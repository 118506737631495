import { _js } from '@ifixit/localize';
import styled from 'styled-components';
import { DropdownContainer, CardColumn } from '../../dropdowns/card_dropdown_parts';
import StoreCard from './store_card';
import { space } from '@ifixit/primitives';
import trackInPiwikAndGA from 'Shared/Analytics/CombinedGAPiwik';
import { StoreType } from '..';

declare const Request: any;

const StyledDropdownContainer = styled(DropdownContainer)<{ $mobile: boolean }>`
   padding-top: ${({ $mobile }) => ($mobile ? space[3] : space[2])};
   padding-bottom: ${space[3]};
`;

const updateStorePreference = (storePreference: StoreType, currentStore: StoreType) => {
   new Request.API_2_0('store/user/store_preference', {
      method: 'POST',
      onSuccess: () => {
         trackInPiwikAndGA({
            eventCategory: 'Preferred Store',
            eventAction: `Preferred Store - ${currentStore.storeCode} - ${storePreference.storeCode}`,
         });
         if (App.storeRedirectLinks && App.storeRedirectLinks[storePreference.storeCode]) {
            window.location = App.storeRedirectLinks[storePreference.storeCode];
         } else if (App.onStorePage && App.storeUrls) {
            window.location = App.storeUrls[storePreference.storeCode];
         } else {
            window.location.reload();
         }
      },
      statusPanelMessage: _js('Submitting'),
   }).send({
      newStore: storePreference.storeCode,
   });
};

const StoreDropdown = ({
   stores,
   mobile,
   currentStore,
}: {
   stores: StoreType[];
   mobile?: boolean;
   currentStore: StoreType;
}) => (
   // @ts-expect-error TS(2769) FIXME: No overload matches this call.
   <StyledDropdownContainer id="storeDropdown" $mobile={mobile}>
      <CardColumn>
         {stores.map((store: StoreType) => (
            <StoreCard
               key={store.name}
               store={store}
               mobile={mobile}
               isDisplayStore={store.isDisplayStore}
               updateStorePreference={() => {
                  updateStorePreference(store, currentStore);
               }}
            />
         ))}
      </CardColumn>
   </StyledDropdownContainer>
);

export default StoreDropdown;
