import { connect } from 'react-redux';
import Dropdown from './dropdown';
import Workbench from 'Shared/workbench';
import trackInPiwikAndGA from 'Shared/Analytics/CombinedGAPiwik';
import { useDisclosure } from '@chakra-ui/react';
import { color } from '@ifixit/primitives';
import { faToolbox } from '@fortawesome/pro-solid-svg-icons/faToolbox';
import { FaIcon } from '@ifixit/icons';

const mapStateToProps = (state: any) => ({
   userTopics: state.headerState.userTopics,
});

interface WorkbenchDropdownProps {
   userTopics: any;
}

const WorkbenchDropdown = (props: WorkbenchDropdownProps) => {
   const { isOpen: tippyToggle, onToggle: onTippyToggle } = useDisclosure({ defaultIsOpen: true });
   const onMount = () => {
      trackInPiwikAndGA({
         eventCategory: 'Workbench',
         eventAction: 'Workbench - Click',
      });
   };

   return (
      <Dropdown
         label="View Workbench"
         onMount={onMount}
         icon={
            <FaIcon
               icon={faToolbox}
               id="workbenchDropdownToggle"
               aria-label="my workbench"
               focusable="false"
               color={color.white}
               boxSize="24px"
            />
         }
         hideOnClick={tippyToggle}
      >
         <Workbench topics={props.userTopics} onTippyToggle={onTippyToggle} />
      </Dropdown>
   );
};

export default connect(mapStateToProps)(WorkbenchDropdown);
