import { formatMoney } from '@ifixit/helpers';
import { _js } from '@ifixit/localize';
import { useCart } from '@ifixit/shopify-cart-sdk';

const Body = () => {
   const cart = useCart();
   const products = cart.data?.lineItems;
   if (!products?.length) {
      return <p className="mini-cart-empty-message">{_js('Looks like your cart is empty')}</p>;
   }

   return (
      <div className="mini-cart-products-container">
         <ul className="mini-cart-products">
            {products.map((product, index) => {
               const imageSrc = product.imageSrc;

               const imageUrl = imageSrc ? new URL(imageSrc) : null;
               imageUrl?.searchParams.set('width', '120');
               imageUrl?.searchParams.set('height', '120');

               const displayName = product.name;
               const shortDisplayName = product.name.slice(0, 35);
               const variantName = product.variantTitle;
               const priceText = formatMoney(product.price, 'en-us');
               return (
                  <li className="mini-cart-product" key={index}>
                     {imageUrl && (
                        <img
                           className="mini-cart-product-image"
                           src={imageUrl.toString()}
                           alt={displayName}
                        />
                     )}
                     <div className="mini-cart-product-info">
                        <a
                           className="mini-cart-product-title"
                           href={product.url}
                           title={displayName}
                        >
                           {shortDisplayName}
                        </a>
                        <span className="mini-cart-product-variant">{variantName}</span>
                        <span className="mini-cart-product-price">
                           {`${product.quantity} × ${priceText}`}
                        </span>
                     </div>
                  </li>
               );
            })}
         </ul>
      </div>
   );
};

export default Body;
