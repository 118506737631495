import { _js } from '@ifixit/localize';
import { store } from 'Shared/react-initialize';
import * as HeaderActions from 'Shared/Header/actions';
import { TopicSelector } from 'Shared/topic_selector';

const WorkbenchManager = {
   addItem: function (topicVariantid, wikiid, useIndicator, topicTitle) {
      topicTitle = topicTitle ? topicTitle : null;
      let request = new Request.API_2_0('user/workbench/add?langid=' + App.lang, {
         method: 'POST',
      }).send({
         wikiid: wikiid,
         topicVariantid: topicVariantid,
         topicTitle: topicTitle,
         productcode: App.productcode,
      });

      request.done(getRequestDoneFunction(wikiid, topicVariantid));

      if (useIndicator) {
         LoadingIndicator.withPromise(request, {
            pendingMessage: _js('Adding'),
            successMessage: _js('Added'),
         });
      }
   },

   addItemWithVariantString: function (wikiid, device, variant, useIndicator) {
      let request = new Request.API_2_0('user/workbench/addWithVariantString?langid=' + App.lang, {
         method: 'POST',
      }).send({
         variant: variant,
         device: device,
         productcode: App.productcode,
      });

      request.done(getRequestDoneFunction(wikiid, variant));

      if (useIndicator) {
         LoadingIndicator.withPromise(request, {
            pendingMessage: _js('Adding'),
            successMessage: _js('Added'),
         });
      }
   },

   removeItem: function (topicVariantid, wikiid, useIndicator) {
      let request = new Request.API_2_0('user/workbench/remove?langid=' + App.lang, {
         method: 'POST',
      }).send({
         wikiid: wikiid,
         topicVariantid: topicVariantid,
      });

      request.done(response => {
         store.dispatch(HeaderActions.updateWorkbench(response.topics));
      });

      if (useIndicator) {
         LoadingIndicator.withPromise(request, {
            pendingMessage: _js('Removing'),
            successMessage: _js('Removed'),
         });
      }
   },

   removeVariants: function (wikiid, useIndicator) {
      let request = new Request.API_2_0('user/workbench/removeVariants?langid=' + App.lang, {
         method: 'POST',
      }).send({
         wikiid: wikiid,
      });

      request.done(response => {
         store.dispatch(HeaderActions.updateWorkbench(response.topics));
      });

      if (useIndicator) {
         LoadingIndicator.withPromise(request, {
            pendingMessage: _js('Removing'),
            successMessage: _js('Removed'),
         });
      }
   },

   setActiveItem: function (dropdownId) {
      dropdownId = dropdownId.split('-');
      let wikiid = parseInt(dropdownId[0], 10);
      let topicVariantid = dropdownId[1];

      let request = new Request.API_2_0('user/workbench/set_active', {
         method: 'POST',
      }).send({
         wikiid: wikiid,
         topicVariantid: topicVariantid,
      });

      request.done(response => {
         store.dispatch(HeaderActions.updateWorkbench(response.topics));
      });
   },

   getCurrentWorkbench: function () {
      let request = new Request.API_2_0('user/workbench/', {
         method: 'GET',
      }).send();

      request.done(response => {
         store.dispatch(HeaderActions.updateWorkbench(response.topics));
      });
   },
};

const getRequestDoneFunction = (wikiid, topicVariant) => result => {
   /* Add the html to workbench */
   if (result) {
      // With the Hydra header, we're storing the topics in
      // Redux so that whenever it changes, the React dropdown can
      // re-render.
      store.dispatch(HeaderActions.updateWorkbench(result.topics));

      if (topicVariant) {
         WorkbenchManager.removeItem(null, wikiid, /* useIndicator */ false);
      }
   }
   /* Remove variant devices if generic is checked */
   if (!topicVariant) {
      TopicSelector.deselectVariantItems(wikiid);
      WorkbenchManager.removeVariants(wikiid);
   }
};

export default WorkbenchManager;
