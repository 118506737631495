import { useCallback, useState, ComponentProps } from 'react';
import styled from 'styled-components';
import Heading from './heading';

const Container = styled.nav`
   position: relative;
   color: white;
   text-align: left;
   width: auto;
`;

const List = styled.ul`
   display: flex;
   height: 100%;
   list-style: none;
   margin: 0;
   padding: 0;
`;

export type StoreType = {
   name: string;
   isDisplayStore: boolean;
   currency: any;
   storeCode:
      | 'us'
      | 'au'
      | 'ca'
      | 'de'
      | 'it'
      | 'fr'
      | 'uk'
      | 'eu'
      | 'eu_pro'
      | 'checkout_dev'
      | 'dev'
      | 'test'
      | 'eu_test'
      | 'eu_pro_test';
};

export type SublinkType = {
   disabled?: boolean;
   url: string;
   title: string;
} & ComponentProps<any>;

export type HeadingLinkType = {
   title: string;
   disabled?: boolean;
   hasStoreDropdown: boolean;
   sublinks: SublinkType[];
};

const Headings = ({ links, stores }: { links: HeadingLinkType[]; stores: StoreType[] }) => {
   const [activeId, setActiveId] = useState(null);

   const headingOpened = useCallback(
      (openedId: any) => {
         setActiveId(activeId === openedId ? null : openedId);
      },
      [activeId, setActiveId]
   );

   return (
      <Container>
         <List>
            {links.map(
               (heading: HeadingLinkType) =>
                  heading.disabled || (
                     <Heading
                        key={`${heading.title}-heading`}
                        info={heading}
                        isActive={activeId === heading.title}
                        headingOpened={headingOpened}
                        stores={stores}
                     />
                  )
            )}
         </List>
      </Container>
   );
};

export default Headings;
