import WorkbenchManager from 'Shared/workbench_manager';

// eslint-disable-next-line no-var
export var TopicSelector = {
   select: function (button, variantid, wikiid, useIndicator) {
      WorkbenchManager.addItem(variantid, wikiid, useIndicator);
      this.toggleButtonState(button, /* isOwned */ true);
   },

   deselect: function (button, variantid, wikiid, useIndicator) {
      WorkbenchManager.removeItem(variantid, wikiid, useIndicator);
      this.toggleButtonState(button, /* isOwned */ false);
   },

   toggleButtonState: function (button, isOwned) {
      let checkIcon = $$('.save-topic-check');
      let plusIcon = $$('.save-topic-plus');

      if (isOwned) {
         button.addClass('checked');
         plusIcon.addClass('hidden');
         checkIcon.removeClass('hidden');
      } else {
         button.removeClass('checked');
         checkIcon.addClass('hidden');
         plusIcon.removeClass('hidden');
      }
   },

   updateVariantDropdownState: function () {
      let button = $$('#variant-save-topic-button, #generic-save-topic-button');

      let owned = $$('.js-workbench-checkbox').some(checkbox => checkbox.get('checked'));

      TopicSelector.toggleButtonState(button, owned);
   },

   deselectVariantItems: function (wikiid) {
      $$('.js-workbench-checkbox').each(checkbox => {
         if (checkbox.get('checked') && checkbox.get('id') !== 'topic-generic') {
            let variantid = checkbox.get('data-topic-variantid');
            let wikiid = checkbox.get('data-wikiid');

            WorkbenchManager.removeItem(variantid, wikiid, /* useIndicator */ false);
            checkbox.set('checked', false);
         }
      });

      //TODO: drop this when #36317 creates method for more reliable behavior
      WorkbenchManager.getCurrentWorkbench();
   },

   uncheckVariant: function (topicVariantid) {
      when($('topic-' + topicVariantid), topicCheckmark => {
         topicCheckmark.set('checked', false);
      });

      this.updateVariantDropdownState();
   },
};
