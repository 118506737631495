import { _js } from '@ifixit/localize';
import { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { color } from '@ifixit/primitives';
import AccordionItem from './accordion_item';
import AccordionSubitem from './accordion_subitem';
import LanguageButton from '../dropdowns/language_button';
import LanguageDropdown from '../dropdowns/language_dropdown';
import StoreFlag from '../headings/store/store_flag';
import StoreDropdown from '../headings/store/store_dropdown';
import { useMediaQuery } from 'react-responsive';
import trackInPiwikAndGA from 'Shared/Analytics/CombinedGAPiwik';
import { HeadingLinkType, StoreType, SublinkType } from '../headings';

const navHeight = '68px';

const Container = styled.div<{ $isOpen?: boolean }>`
   position: absolute;
   padding: 32px 16px 0;
   top: ${navHeight};
   left: 0;
   right: 0;
   height: calc(100vh - ${navHeight});
   background: ${color.gray[900]};
   opacity: 0;
   visibility: hidden;
   transform: translateX(-100%);
   transition: all 0.25s;
   overflow-y: scroll;
   -webkit-overflow-scrolling: touch;
   z-index: 200;
   width: 100vw;

   ${props =>
      props.$isOpen &&
      css`
         opacity: 1;
         visibility: visible;
         transform: translateX(0);
      `};
`;

const mapStateToProps = (state: any) => ({
   headerState: state.headerState,
});

const regionAccordionTitle = _js('Language / Region');

const MobileMenu = ({
   headerState,
   links,
   languageDropdown,
   stores,
   isOnCart,
}: {
   headerState: any;
   links: HeadingLinkType[];
   languageDropdown: any;
   stores: StoreType[];
   isOnCart: boolean;
}) => {
   const { displayMobileMenu } = headerState;

   const [activeId, setActiveId] = useState(links[0].title);

   const accordionItemClicked = useCallback(
      (clickedId: any) => {
         setActiveId(activeId === clickedId ? null : clickedId);
      },
      [activeId, setActiveId]
   );

   const { languages, translationPreferencesUrl } = languageDropdown;

   const selectedLanguage = languages.find((language: any) => language.isSelected);
   const currentStore = stores.find((store: StoreType) => store.isDisplayStore);

   const fullWidthDropdown = useMediaQuery({ maxWidth: '450px' });

   return (
      <Container id="mobileMenuContainer" $isOpen={displayMobileMenu}>
         {links.map(
            (data: any) =>
               data.disabled || (
                  <AccordionItem
                     key={`accordion-item-${data.title}`}
                     title={data.title}
                     isActive={data.title === activeId}
                     onClick={accordionItemClicked}
                  >
                     {data.sublinks.map(
                        (sublink: SublinkType, index: any) =>
                           sublink.disabled || (
                              <AccordionSubitem
                                 href={sublink.url}
                                 key={`accordion-${data.title}-sublink-${index}`}
                                 title={sublink.title}
                              />
                           )
                     )}
                  </AccordionItem>
               )
         )}
         <AccordionItem
            title={regionAccordionTitle}
            isActive={regionAccordionTitle === activeId}
            onClick={accordionItemClicked}
         >
            {!isOnCart && (
               <AccordionSubitem
                  title={selectedLanguage.autoglottonym}
                  icon={<LanguageButton isoCode={selectedLanguage.isoCode} />}
                  fullWidthDropdown={fullWidthDropdown}
                  dropdown={
                     <LanguageDropdown
                        translationPreferencesUrl={translationPreferencesUrl}
                        languages={languages}
                        isLightTheme
                        mobile={fullWidthDropdown}
                     />
                  }
                  onMount={() => {
                     trackInPiwikAndGA({
                        eventCategory: 'Language',
                        eventAction: 'Language Dropdown - Click',
                     });
                  }}
               />
            )}
            <AccordionSubitem
               // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
               title={currentStore.name}
               // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
               icon={<StoreFlag storeCode={currentStore.storeCode} />}
               fullWidthDropdown={fullWidthDropdown}
               dropdown={
                  <StoreDropdown
                     stores={stores}
                     // @ts-expect-error TS(2322) FIXME: Type 'StoreType | undefined' is not assignable to ... Remove this comment to see the full error message
                     currentStore={currentStore}
                     mobile={fullWidthDropdown}
                  />
               }
               onMount={() => {
                  trackInPiwikAndGA({
                     eventCategory: 'Preferred Store',
                     eventAction: 'Preferred Store Dropdown - Click',
                  });
               }}
            />
         </AccordionItem>
      </Container>
   );
};

export default connect(mapStateToProps)(MobileMenu);
