import { _js } from '@ifixit/localize';
import { useContext } from 'react';
import styled from 'styled-components';
import { color, space, fontSize, fontWeight } from '@ifixit/primitives';
import { Avatar } from '@chakra-ui/react';
import { BreakpointsContext } from 'Shared/utils/breakpoints';
import Dropdown from './dropdown';
import MenuButton from './menu_button';
import { UserInfoType } from '.';
import Notifications from 'Shared/Notifications/notifications';
import { facebookLogout } from 'Shared/facebookHelper';

declare global {
   interface Window {
      shared_constants: Record<string, any>;
   }
}

const Container = styled.div`
   text-align: left;
`;

const Section = styled.div<{ $padding?: string }>`
   background: white;
   text-align: left;
   border-bottom: 1px solid ${color.gray[200]};
   padding: ${props => props.$padding || space[2] + ' 0'};
`;

const Title = styled.div`
   font-size: ${fontSize.lg};
   color: ${color.gray[900]};
   margin: 0;
   padding: 0px;
   font-weight: ${fontWeight.semiBold};
   line-height: 1.21em;
`;

const Subtitle = styled.div`
   font-size: ${fontSize.lg};
   font-weight: normal;
   color: ${color.gray[600]};
   margin-top: 8px;
   line-height: 1.21em;
`;

const UserDropdown = ({
   username,
   uniqueUsername,
   profileUrl,
   ordersUrl,
   notificationsUrl,
   avatar,
   team,
   canIViewManageTab,
   notifications,
}: UserInfoType & { notifications: Notifications }) => {
   const breakpoints = useContext(BreakpointsContext);

   return (
      <Dropdown
         label="User Information"
         icon={
            <Avatar
               w={{ base: '24px', lg: '32px' }}
               h={{ base: '24px', lg: '32px' }}
               id="profileMenuToggle"
               src={avatar}
               aria-label="profile"
            />
         }
         hasBadge={!!(breakpoints.isMobileTablet() && notifications.getUnreadCount() > 0)}
      >
         <Container>
            <Section $padding={space[4]}>
               <Title>{username}</Title>
               {uniqueUsername && <Subtitle>&#x40;{uniqueUsername}</Subtitle>}
            </Section>
            <Section id="profileMenu">
               <MenuButton
                  url={notificationsUrl}
                  text={_js('Notifications')}
                  badge={notifications.getUnreadCount()}
               />
               <MenuButton id="myAccount" url={profileUrl} text={_js('View Profile')} />
               {team && (
                  <MenuButton
                     url={team.teamLink}
                     text={team.teamCount > 1 ? _js('My Teams') : _js('My Team')}
                  />
               )}
               <MenuButton url={ordersUrl} text={_js('Orders')} />
               {canIViewManageTab && <MenuButton url={'/Manage'} text={_js('Manage')} />}
            </Section>
            <Section>
               <MenuButton
                  onClick={() => facebookLogout()}
                  id="navLogout"
                  url={window.shared_constants.GuideURI('TAG_LOGOUT')}
                  text={_js('Log Out')}
               />
            </Section>
         </Container>
      </Dropdown>
   );
};

export default UserDropdown;
